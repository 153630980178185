import '@fortawesome/fontawesome-free/css/all.css'
import { t, initI18n } from 'ftc-common/i18n';
import './main.scss'
import UAParser from 'ua-parser-js';
import { messageDialog, confirmDialog, promptDialog } from 'first-common/dialogs'

initI18n();

Object.assign(window, {
    t, messageDialog, confirmDialog, promptDialog
})

const chromiumBasedMinimum = 80;

const browserMinimums = {
    Chrome: chromiumBasedMinimum,
    ['Chrome Headless']: chromiumBasedMinimum,
    Chromium: chromiumBasedMinimum,
    ['Mobile Safari']: 15,
    Safari: 15, // Mobile only, checked below
    Silk: chromiumBasedMinimum,
    // Not officially supported, but known to work
    Edge: chromiumBasedMinimum,
    Firefox: 115
}

const browser = UAParser().browser;
let message;
// We need this gross logic because ipads pretend to be desktop macs
const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
if (browser.name in browserMinimums && (browser.name != 'Safari' || isIOS)) {
    const majorVersion = parseInt(browser.version.split('.')[0]);
    if (majorVersion < browserMinimums[browser.name]) {
        message = t`The FTC Scoring software only supports ${browser.name} version ${browserMinimums[browser.name]} or higher. You are currently running ${browser.version}. Please use a supported browser.`;
    }
} else {
    message = t`The FTC Scoring software does not support ${browser.name}. Please use a supported browser.`;
}
if(message) {
    const warningElement = document.getElementById("browser-warning");
    if(warningElement) {
        warningElement.classList.remove("d-none");
        warningElement.getElementsByClassName("warning-text")[0].innerHTML = message;
    }
}